import React, { useState, useEffect } from 'react';
import { CommentOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import createFeedback from '../utils/createFeedback';
import {setFeedbackSubmitted} from '../utils/feedbackStatus';
import { toast } from 'react-hot-toast';

const FeedbackWidget = ({ source }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false); // Track if feedback is submitted for the source
  const { t } = useTranslation();
  const sourceLabel = t(`feedback_widget.sources.${source}`);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleFeedbackSubmit = async (event) => {
    event.preventDefault();

    // Validate the length of feedback content
    if (feedback.length < 10) {
      toast.error(t('feedback_widget.validation.min_length'));
      return;
    }

    try {
      setLoading(true); // Set loading to true when submitting
      await createFeedback(source, feedback);
      toast.success(t('feedback_widget.success'));

      // Mark the feedback as submitted in local storage
      setFeedbackSubmitted(source)

      setFeedback(''); // Clear feedback after submission
      setIsOpen(false); // Optionally collapse after submitting
      setHasSubmitted(true); // Hide the widget after submission
    } catch (error) {
      toast.error(t('common.errors.general'));
    } finally {
      setLoading(false);
    }
  };

  // If feedback has already been submitted, don't show the widget
  if (hasSubmitted) {
    return null;
  }

  // Define styles as objects
  const widgetStyle = {
    container: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      margin: '10px',
      width: isOpen ? '400px' : '180px',
      backgroundColor: '#686e7d',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
      borderRadius: '10px 10px 0 0',
      transition: 'width 0.3s ease',
      zIndex: 1000,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#686e7d',
      color: 'white',
      borderRadius: '10px 10px 0 0',
      cursor: 'pointer',
    },
    content: {
      padding: '10px',
      display: isOpen ? 'block' : 'none',
    },
    textarea: {
      width: '100%',
      padding: '8px',
      borderRadius: '5px',
      border: '1px solid #d9d9d9',
      fontFamily: 'inherit',
      marginBottom: '10px',
    },
    button: {
      padding: '10px 15px',
      backgroundColor: 'var(--main-color)',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      width: '100%',
    },
  };

  return (
    <div style={widgetStyle.container}>
      {/* Header to toggle the widget */}
      <div style={widgetStyle.header} onClick={toggleOpen}>
        <span>{ t('feedback_widget.title', { source: sourceLabel }) }</span>
        {isOpen ? <CloseOutlined /> : <CommentOutlined />}
      </div>

      {/* Feedback form (only visible when open) */}
      <div style={widgetStyle.content}>
        <form onSubmit={handleFeedbackSubmit}>
          <textarea
            style={widgetStyle.textarea}
            rows="4"
            placeholder={t('feedback_widget.placeholder')}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            minLength={10} // Enforcing the minimum length in HTML
            disabled={loading}
          />
          <button style={widgetStyle.button} type="submit" disabled={loading}>
            {loading ? 'Submitting...' : t('feedback_widget.submit')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FeedbackWidget;