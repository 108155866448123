import React, { useEffect } from 'react';
import { Card, Typography, Skeleton, Layout } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { Content } = Layout;

const cardStyle = {
  maxWidth: '1000px',
};

const textContainerStyle = {
  textAlign: 'center',
  marginTop: '16px',
};

const secondaryTextStyle = {
  marginTop: '8px',
  display: 'block',
};

const CaseLoadingCard = ({ isProcessingEntry }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Content className="page">
      <Card style={cardStyle}>
        <div style={textContainerStyle}>
          <Text>
            {isProcessingEntry 
              ? t('case_details.fields.updating_note') 
              : t('case_details.fields.loading_case')}
          </Text>
          <Text style={secondaryTextStyle} type="secondary">
            {t('case_details.fields.loading_message')}
          </Text>
        </div>
        <Skeleton active />
      </Card>
    </Content>
  );
};

export default CaseLoadingCard;
