import { useEffect } from 'react';

const usePageMeta = (title, content = title) => {
  useEffect(() => {
    if (!title) return;

    // Truncate title to 60 characters and add "..." if necessary
    const truncatedTitle = title.length > 60 ? `${title.substring(0, 60)}...` : title;
    const fullTitle = `${truncatedTitle} | Itaca`;

    // Update the document title
    document.title = fullTitle;

    // Update or create the og:title and regular title meta tags
    const updateOrCreateMetaTag = (name, property, contentValue) => {
      let metaTag = document.querySelector(`meta[${property}="${name}"]`);
      if (metaTag) {
        metaTag.setAttribute('content', contentValue);
      } else {
        metaTag = document.createElement('meta');
        metaTag.setAttribute(property, name);
        metaTag.setAttribute('content', contentValue);
        document.head.appendChild(metaTag);
      }
    };

    updateOrCreateMetaTag('title', 'name', fullTitle);
    updateOrCreateMetaTag('og:title', 'property', fullTitle);

    // Update or create the og:description and regular description meta tags
    updateOrCreateMetaTag('description', 'name', content);
    updateOrCreateMetaTag('og:description', 'property', content);
  }, [title, content]);
};

export default usePageMeta;
