import moment from 'moment';

class QueryPresenter {
  constructor(data) {
    this.uuid = data.uuid;
    this.question = data.question;
    this.genAnswer = data.gen_answer;
    this.completed = data.completed;
    this.shareToken = data.share_token; // Specific to QueryPresenter
    this.citations = data.gen_citations;
    this.publishedAt = data.published_at; // Optional, useful for subclasses
    this.slug = data.slug; // Optional, useful for subclasses
  }

  checkValue(value) {
    return value ? value : 'N/A';
  }

  getUUID() {
    return this.checkValue(this.uuid);
  }

  getQuestion() {
    const question = this.checkValue(this.question);
    return this.formatQuestion(question);
  }

  formatQuestion(question) {
    return question; // Default behavior; subclasses can override
  }

  getAnswer() {
    return this.genAnswer;
  }

  getCitations() {
    return this.citations;
  }

  getPublishedAt(format = 'MMMM D, YYYY') {
    return this.publishedAt ? moment(this.publishedAt).format(format) : 'N/A';
  }

  isCompleted() {
    return Boolean(this.completed);
  }

  hasAnswer() {
    return !!this.genAnswer;
  }

  getShareToken() {
    return this.shareToken;
  }

  getShareUrl() {
    return `${window.location.origin}/q/share/${this.getShareToken()}`;
  }
}

export default QueryPresenter;
