import authRequest from '../authRequest';
import SessionPresenter from '../../presenters/SessionPresenter';

const fetchSession = async (uuid) => {
    try {
        const response = await authRequest(`/sessions/${uuid}`, { method: 'GET' });
        return new SessionPresenter(response.data);
    } catch (error) {
        console.error('Fetching session failed:', error);
        throw error;
    }
};

export default fetchSession;
