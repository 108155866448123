// Helper function to get the current feedback status object from localStorage
const getFeedbackStatus = () => {
  const feedbackStatus = localStorage.getItem('feedbackSubmitted');
  return feedbackStatus ? JSON.parse(feedbackStatus) : {};
};

// Check if feedback has been submitted for a specific source
export const hasSubmittedFeedback = (source) => {
  const feedbackStatus = getFeedbackStatus();
  return !!feedbackStatus[source]; // Return true if the source exists and is true
};

// Mark feedback as submitted for a specific source
export const setFeedbackSubmitted = (source) => {
  const feedbackStatus = getFeedbackStatus();
  feedbackStatus[source] = true; // Set the value to true for the given source
  localStorage.setItem('feedbackSubmitted', JSON.stringify(feedbackStatus)); // Save back to localStorage
};

// Optionally, add a function to clear feedback for a specific source or reset the entire status
export const clearFeedbackSubmitted = (source) => {
  const feedbackStatus = getFeedbackStatus();
  delete feedbackStatus[source]; // Remove the source from the feedback status object
  localStorage.setItem('feedbackSubmitted', JSON.stringify(feedbackStatus)); // Save back to localStorage
};

// Concise function name to check if feedback hasn't been submitted
export const noSubmittedFeedback = (source) => {
  return !hasSubmittedFeedback(source);
};