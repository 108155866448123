import authRequest from '../authRequest';
import CaseQueryPresenter from '../../presenters/CaseQueryPresenter';

const fetchCaseQuery = async (queryUuid) => {
  try {
    const response = await authRequest(`/case_queries/${queryUuid}`, { method: 'GET' });
    if (response.status !== 200) {
      throw new Error('Fetching case query failed');
    }
    return new CaseQueryPresenter(response.data);
  } catch (error) {
    console.error('Fetching case query failed:', error);
    throw error;
  }
};

export default fetchCaseQuery;
