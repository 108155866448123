import React, { useState, useEffect } from 'react';
import { Skeleton, Space, Layout, Card, Typography, Pagination, Divider } from "antd";
import { useTranslation } from 'react-i18next';
import fetchPublicQueriesList from '../utils/query/fetchPublicQueriesList';
import { Link } from "react-router-dom";
import PublicQueryPresenter from '../presenters/PublicQueryPresenter';

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

const PublicQueries = () => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const { t } = useTranslation();

  const fetchQueries = async (page) => {
    setLoading(true);
    try {
      const { queries, pagination } = await fetchPublicQueriesList(page);
      setQueries(queries);
      setTotalEntries(pagination.total_entries);
      setCurrentPage(pagination.current_page);
    } catch (error) {
      console.error('Error fetching public queries:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQueries(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <Content className="page public-queries-page public-queries--skeleton">
        <Skeleton active />
        <Space />
        <Skeleton active />
        <Space />
        <Skeleton active />
      </Content>
    );
  }

  return (
    <Content className="page public-queries-page">
      <div className="hero-section" style={{ 
        marginBottom: '1.5rem', 
        textAlign: 'center' 
      }}>
        <Title level={2} style={{ 
          marginBottom: '0.75rem',
          lineHeight: '1.2'
        }}>
          {t('public_queries.hero.title', 'Featured Clinical Questions and Answers')}
        </Title>
        <Paragraph style={{ 
          fontSize: 'calc(0.9rem + 0.3vw)', 
          maxWidth: '90%', 
          margin: '0 auto', 
          color: 'rgba(0, 0, 0, 0.65)',
          lineHeight: '1.5'
        }}>
          {t('public_queries.hero.description')}
        </Paragraph>
      </div>

      <Card style={{ marginTop: '2rem' }}>
        {queries.map((query, index) => (
          <React.Fragment key={query.getUUID()}>
            {index > 0 && <Divider style={{ margin: '2rem 0' }} />}
            <article style={{ marginBottom: '2rem' }}>
              <Link to={query.getPublicUrl()}>
                <Title level={4} style={{ marginBottom: '0.5rem' }}>{query.getQuestion()}</Title>
              </Link>
              <Text italic type="secondary" style={{ display: 'block', marginBottom: '1rem', fontSize: '0.9rem' }}>
                {t('public_queries.answered_on')} {query.getPublishedAt()}
              </Text>
              <Paragraph
                ellipsis={{
                  rows: 3,
                }}
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.6',
                  color: 'rgba(0, 0, 0, 0.65)',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {query.getExcerpt()}
                <div style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '50%',
                  background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
                  pointerEvents: 'none',
                }} />
              </Paragraph>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '1rem' }}>
                <Link to={query.getPublicUrl()}>
                  <Text style={{ color: '#1890ff' }}>{t('public_queries.read_more')}</Text>
                </Link>
              </div>
            </article>
          </React.Fragment>
        ))}
      </Card>
      <div style={{ marginTop: '2rem', textAlign: 'center' }}>
        <Pagination
          current={currentPage}
          total={totalEntries}
          pageSize={10}
          onChange={handlePageChange}
          hideOnSinglePage={true}
          showQuickJumper={false}
          showSizeChanger={false}
          style={{ marginTop: '16px', textAlign: 'center' }}
        />
      </div>
    </Content>
  );
};

export default PublicQueries;
