import React from 'react';
import { Typography, List } from 'antd';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const QueryCitations = ({ citations }) => {
  const { t } = useTranslation();

  if (!citations || citations.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Title level={2}>{t('query_details.fields.sources')}</Title>
      <List
        dataSource={citations}
        itemLayout="vertical" // Use vertical layout but tweak styles
        renderItem={(citation, index) => (
          <List.Item
            id={`citation-${index + 1}`}
            style={{ padding: '4px 0' }} // Reduce vertical padding
          >
            <a href={citation} target="_blank" rel="noopener noreferrer">
              [{index + 1}] {citation}
            </a>
          </List.Item>
        )}
      />
    </div>
  );
};

export default QueryCitations;