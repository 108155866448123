import React from 'react';
import { Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../GoogleSignIn.css';

let handleSignIn = (origin, locale = null) => {
  const server_host = process.env.REACT_APP_SERVER_HOST;
  let googleRedirectUrl = `${server_host}/auth/google_oauth2`;
  const queryParams = [];

  if (origin && origin !== '/') {
    queryParams.push(`origin=${encodeURIComponent(origin)}`);
  }
  if (locale) {
    queryParams.push(`locale=${encodeURIComponent(locale)}`);
  }

  // Append query parameters, if any exist
  if (queryParams.length > 0) {
    googleRedirectUrl += `?${queryParams.join('&')}`;
  }
  console.log('redirecting to ', googleRedirectUrl);
  window.location.href = googleRedirectUrl;
};

// used in tests
export const setHandleSignIn = (func) => {
  handleSignIn = func;
};

const GoogleSignIn = ({ origin = '/', locale = null }) => {
  const { t } = useTranslation();

  return (
    <Button onClick={() => handleSignIn(origin, locale)} icon={<GoogleOutlined />} type="secondary" className="google-signin-btn" data-testid="google-signin-button">
      { t('sign_in.fields.continue_with_google') }
    </Button>
  );
};

export default GoogleSignIn;
