import authRequest from '../authRequest';
import QueryPresenter from "../../presenters/QueryPresenter";

const createQuery = async (question, previousQueryUuid = null, isDemo = false) => {
  try {
    const data = { query: { question } };
    if (previousQueryUuid) {
      data.query.previous_query_uuid = previousQueryUuid;
    }

    data.query.demo = isDemo;

    const response = await authRequest('/queries', {
      method: 'POST',
      data,
    });
    if (response.status === 200) {
      return new QueryPresenter(response.data);
    } else {
      throw new Error('Failed to create query');
    }
  } catch (error) {
    console.error('Creating query failed:', error);
    throw error;
  }
};

export default createQuery;
