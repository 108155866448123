import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Skeleton, Layout } from 'antd';
import QueryDetailsCard from '../components/query/QueryDetailsCard';
import fetchPublicQuery from '../utils/query/fetchPublicQuery';
import NotFoundPage from "./NotFoundPage";
import usePageMeta from '../hooks/usePageMeta';

const { Content } = Layout;

const PublicQueryDetails = () => {
  const { slug, uuid } = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadQuery = async () => {
      try {
        const publicQueryPresenter = await fetchPublicQuery(uuid);
        setQuery(publicQueryPresenter);
        
        // Check if the current slug matches the query's slug
        if (publicQueryPresenter.getSlug() !== slug) {
          // If not, redirect to the correct URL
          navigate(`/q/${publicQueryPresenter.getSlug()}/${uuid}`, { replace: true });
        }
      } catch (error) {
        setError('Failed to load the shared query.');
      } finally {
        setLoading(false);
      }
    };

    loadQuery();
  }, [uuid, slug, navigate]);

  // Use the usePageMeta hook to dynamically set metadata for SEO
  usePageMeta(query?.getQuestion());

  if (loading) return (
    <Content className="page public-page">
      <Skeleton active />
    </Content>
  );
  if (error) return <NotFoundPage />;

  return (
    <Content className="page public-page public-queries-page">
      <QueryDetailsCard query={query} protectedPage={false} />
    </Content>
  );
};

export default PublicQueryDetails;
