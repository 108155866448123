import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import createCaseQuery from '../utils/case/createCaseQuery';
import fetchCaseQuery from '../utils/case/fetchCaseQuery';
import { handleError } from "../utils/errorHandling";

const useCaseQuerySubmission = (caseUuid, initialCaseQueries) => {
  const [queryFeed, setQueryFeed] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const pollQuery = useCallback(async (queryUuid) => {
    let pollResponse;
    do {
      pollResponse = await fetchCaseQuery(queryUuid);
      setQueryFeed(prevFeed => 
        prevFeed.map(item => 
          item.uuid === queryUuid 
            ? { ...item, answer: pollResponse.getAnswer(), completed: pollResponse.isCompleted() }
            : item
        )
      );
      if (!pollResponse.isCompleted()) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    } while (!pollResponse.isCompleted());

    return pollResponse;
  }, []);

  useEffect(() => {
    if (initialCaseQueries && initialCaseQueries.length > 0) {
      const formattedQueries = initialCaseQueries.map(q => ({
        question: q.question,
        answer: q.gen_answer,
        uuid: q.uuid,
        completed: q.completed
      }));
      setQueryFeed(formattedQueries);

      // Start polling for the latest uncompleted query
      const latestQuery = formattedQueries[formattedQueries.length - 1];
      if (latestQuery && !latestQuery.completed) {
        pollQuery(latestQuery.uuid);
      }
    }
  }, [initialCaseQueries, pollQuery]);

  const submitQuery = useCallback(async (query) => {
    if (!query.trim() || isSubmitting) return;

    try {
      setIsSubmitting(true);
      const response = await createCaseQuery(caseUuid, query);
      if (response && response.uuid) {
        const queryUuid = response.uuid;

        const newQuery = { question: query, answer: '', uuid: queryUuid, completed: false };
        setQueryFeed(prevFeed => [...prevFeed, newQuery]);

        await pollQuery(queryUuid);
      } else {
        throw new Error('Invalid response received');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [caseUuid, isSubmitting, pollQuery]);

  return { queryFeed, isSubmitting, submitQuery };
};

export default useCaseQuerySubmission;
