import QueryPresenter from './QueryPresenter';

class PublicQueryPresenter extends QueryPresenter {
  constructor(data) {
    super(data);
  }

  formatQuestion(question) {
    // Capitalize the first letter of the question
    return question.charAt(0).toUpperCase() + question.slice(1);
  }

  getSlug() {
    let slug = this.checkValue(this.slug);
    if (slug.endsWith('-')) {
      slug = slug.slice(0, -1);
    }
    return slug;
  }

  getExcerpt(length = 300) {
    console.log('getExcerpt', this.genAnswer);
    if (!this.genAnswer) return 'N/A';

    let answer = this.genAnswer;
    const summaryHeaders = ['### Summary', '### Resumen'];

    for (const header of summaryHeaders) {
      if (answer.startsWith(header)) {
        answer = answer.replace(header, '').trim();
        break;
      }
    }

    if (answer.length <= length) return answer;
    return answer.substring(0, length) + '...';
  }

  getPublicUrl() {
    return `/q/${this.getSlug()}/${this.getUUID()}`;
  }
}

export default PublicQueryPresenter;
