import authRequest from '../authRequest';
import QueryPresenter from "../../presenters/QueryPresenter";

const fetchQuery = async (uuid) => {
  try {
    const response = await authRequest(`/queries/${uuid}`, { method: 'GET' });
    if (response.status === 200) {
      return new QueryPresenter(response.data);
    } else {
      throw new Error('Failed to create query');
    }
  } catch (error) {
    console.error('Fetching query failed:', error);
    throw error;
  }
};

export default fetchQuery;
