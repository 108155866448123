import React from 'react';
import { Empty, Button, Typography, Space, Card } from 'antd';
import { FolderOpenTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import FeatureBenefits from "../FeatureBenefits";

const { Title, Paragraph } = Typography;

const cardStyle = {
  width: '100%',
  textAlign: 'center',
};

const spaceStyle = {
  width: '100%',
};

const CaseEmptyState = ({ showModal }) => {
  const { t } = useTranslation();

  return (
    <Card
      style={cardStyle}
      data-testid="case-empty-state-container"
    >
      <Space direction="vertical" size="large" style={spaceStyle}>
        <Empty
          image={<FolderOpenTwoTone style={{ fontSize: '60px', marginBottom: '10px' }} />}
          description={<Title level={3} style={{marginTop: '0px'}}>{t('case_empty_state.title')}</Title>}
        />
        <FeatureBenefits featureKey='case_empty_state' />
        <Button 
          type="primary"
          shape="round"
          size="large"
          onClick={showModal}
        >
          {t('cases.new_case')}
        </Button>
      </Space>
    </Card>
  );
};

export default CaseEmptyState;
