import React from 'react';
import { Tag } from 'antd';

const ExampleQuestionButton = ({ text, onClick }) => {
  return (
    <Tag
      color="blue"
      onClick={onClick}
      style={{
        cursor: 'pointer',
        padding: '5px 10px',
        borderRadius: '15px',
        marginBottom: '8px',
        whiteSpace: 'normal',
        height: 'auto',
        maxWidth: '100%'
      }}
    >
      {text}
    </Tag>
  );
};

export default ExampleQuestionButton;
