import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {Skeleton} from 'antd';
import QueryDetailsCard from '../components/query/QueryDetailsCard';
import fetchSharedQuery from '../utils/query/fetchSharedQuery';
import NotFoundPage from "./NotFoundPage";
import usePageMeta from '../hooks/usePageMeta';

const SharedQuery = () => {
  const { share_token } = useParams();
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadQuery = async () => {
      try {
        const data = await fetchSharedQuery(share_token);
        setQuery(data);
      } catch (error) {
        setError('Failed to load the shared query.');
      } finally {
        setLoading(false);
      }
    };

    loadQuery();
  }, [share_token]);

  // Use the usePageMeta hook to set the title, content, og:title
  usePageMeta(query?.question);

  if (loading) return (
    <div className='public-query-share-page'>
      <Skeleton active />
    </div>
  );
  if (error) return <NotFoundPage />;

  return (
    <div className='public-query-share-page'>
      <QueryDetailsCard query={query} protectedPage={false} />
    </div>
  );
};

export default SharedQuery;
