import authRequest from '../authRequest';
import CasePresenter from '../../presenters/CasePresenter';

const fetchCase = async (caseUuid) => {
    try {
        const response = await authRequest(`/cases/${caseUuid}`, { method: 'GET' });
        if (response.status !== 200) {
            throw new Error('Fetching case failed');
        }
        return new CasePresenter(response.data);
    } catch (error) {
        console.error('Fetching case failed:', error);
        throw error;
    }
};

export default fetchCase;
