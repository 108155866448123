import React, { useState, useEffect } from 'react';
import NoteCardView from './NoteCardView';
import NoteCardEdit from './NoteCardEdit';
import fetchNote from '../../utils/session/fetchNote';
import { Skeleton } from 'antd';
import { logEvent } from '../../analytics';

const NoteCard = ({ note, session }) => {
  const [currentNote, setCurrentNote] = useState(note);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(currentNote && !currentNote.isComplete());

  const POLLING_INTERVAL = 2000; // 2 seconds

  useEffect(() => {
    let intervalId;

    if (loading) {
      intervalId = setInterval(async () => {
        try {
          const updatedNote = await fetchNote(note.getUUID());
          setCurrentNote(updatedNote);
          if (updatedNote.isComplete()) {
            setLoading(false);
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error('Fetching note failed:', error);
          clearInterval(intervalId);
        }
      }, POLLING_INTERVAL);
    }

    return () => clearInterval(intervalId);
  }, [note, loading]);

  const handleSaveButtonClick = () => {
    logEvent('Note', 'EditSave');
    setIsEditing(false);
  };

  const handleCancelButtonClick = () => {
    logEvent('Note', 'EditCancel');
    setIsEditing(false);
  };

  return (
    <div>
      {isEditing ? (
        <NoteCardEdit
          currentNote={currentNote}
          session={session}
          handleSaveButtonClick={handleSaveButtonClick}
          handleCancelButtonClick={handleCancelButtonClick}
        />
      ) : loading ? (
        <div data-testid='note-card-skeleton-loading'>
          <Skeleton active />
        </div>
      ) : (
        <NoteCardView
          currentNote={currentNote}
          session={session}
          handleEditButtonClick={() => setIsEditing(true)}
        />
      )}
    </div>
  );
};

export default NoteCard;
