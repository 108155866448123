import React from 'react';
import ReactMarkdown from 'react-markdown';

const AnswerMarkdown = ({ children }) => {
  // Function to process citations
  const processCitations = (text) => {
    // Replace grouped citations like [n][m][k] with [n](#citation-n)[m](#citation-m)[k](#citation-k)
    return text.replace(/(\[(\d+)\])+/g, (match) => {
      // Split the match into individual citations
      const citations = match.match(/\[(\d+)\]/g);
      // Convert each citation to a markdown link
      return citations
        .map((citation) => {
          const number = citation.slice(1, -1);
          return `[${number}](#citation-${number})`;
        })
        .join('');
    });
  };

  // Ensure that children is a string before processing
  const processedText = typeof children === 'string' ? processCitations(children) : children;

  return (
    <ReactMarkdown
      components={{
        // Customize link rendering
        a: ({ node, ...props }) => {
          const href = props.href || '';
          if (href.startsWith('#citation-')) {
            // Internal citation link
            const citationNumber = href.replace('#citation-', '');
            return (
              <a
                href={href}
                onClick={(e) => {
                  e.preventDefault();
                  const citationElement = document.getElementById(`citation-${citationNumber}`);
                  if (citationElement) {
                    citationElement.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
              >
                [{props.children}]
              </a>
            );
          } else {
            // External link
            return (
              <a
                {...props}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'underline',
                  color: '#007BFF',
                }}
              >
                {props.children}
              </a>
            );
          }
        },
      }}
    >
      {processedText}
    </ReactMarkdown>
  );
};

export default AnswerMarkdown;