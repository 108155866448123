import authRequest from './authRequest';

const createFeedback = async (source, content) => {
  try {
    const response = await authRequest('/feedbacks', {
      method: 'POST',
      data: {
        source: source,
        content: content,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Submitting feedback failed:', error);
    throw error;
  }
};

export default createFeedback;