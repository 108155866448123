import React from 'react';
import { Grid } from 'antd';
import FeedbackWidget from './FeedbackWidget';
import { noSubmittedFeedback } from '../utils/feedbackStatus';

const { useBreakpoint } = Grid;

const FeedbackWrapper = ({ source }) => {
  const screens = useBreakpoint();

  // Check if feedback has not been submitted and the screen size is medium or larger
  if (screens.md && noSubmittedFeedback(source)) {
    return <FeedbackWidget source={source} />;
  }

  // If feedback has been submitted or the screen size is smaller, return null
  return null;
};

export default FeedbackWrapper;