import React, { useState } from 'react';
import { Card, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import updateSession from '../../utils/session/updateSession';
import createNote from '../../utils/session/createNote';
import {handleError} from "../../utils/errorHandling";

const { TextArea } = Input;

const SessionDetailsNotepad = ({ session }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notes, setNotes] = useState(session.getUserNotes() || '');

  const onUpdateUserNotes = async () => {
    try {
      // Update the session with the new user notes
      await updateSession(session.getUUID(), notes);

      // Create a new note using the same note-type-slug as the last note
      await createNote(session.getUUID(), session.getLastDoctorNoteType());

      // Redirect to the session URL with the generating parameter
      const randomNum = Math.floor(Math.random() * 100) + 1;
      navigate(`/sessions/${session.getUUID()}?generating=${randomNum}`);
    } catch (error) {
      handleError(error)
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onUpdateUserNotes();
    }
  };

  return (
    <Card
      title={t('session_details_notepad.title')}
      style={{
        background: 'white',
        border: '1px solid #f0f0f0',
        marginBottom: '4px',
      }}
    >
      <div className='session-notepad'>
        <TextArea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={t('session_details_notepad.placeholder')}
          autoSize
          variant="borderless"
          style={{
            resize: 'none',
            padding: 0,
            boxShadow: 'none',
            backgroundColor: 'transparent',
          }}
          data-testid="notepad-textarea"
        />
      </div>
    </Card>
);
};

export default SessionDetailsNotepad;