import React, { useEffect } from 'react';
import { ErrorBoundary } from './bugsnag';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';
import { Toaster } from 'react-hot-toast';
import PublicLayout from './components/layouts/PublicLayout';
import ProtectedLayout from './components/layouts/ProtectedLayout';
import BlankLayout from "./components/layouts/BlankLayout";
import LegalDocument from "./components/LegalDocument";
import Home from './pages/Home';
import Settings from './pages/Settings';
import Sessions from './pages/Sessions';
import SessionDetails from './pages/SessionDetails';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import EmailConfirmation from './pages/EmailConfirmation';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import ResetPasswordForm from "./pages/ResetPasswordForm";
import NotFoundPage from './pages/NotFoundPage';
import GoogleAuthCallback from './pages/GoogleAuthCallback';
import QueryDetails from './pages/QueryDetails';
import QueryNew from './pages/QueryNew';
import SharedQuery from './pages/SharedQuery';
import PublicQueryDetails from './pages/PublicQueryDetails';
import PublicQueries from './pages/PublicQueries';
import QueryFromUrl from './components/query/QueryFromUrl';
import { initGA } from './analytics';
import PageViewHandler from './components/PageViewHandler';
import { useRetryUpload } from './hooks/useRetryUpload';
import { useNetworkStatus } from './hooks/useNetworkStatus';
import './i18n';
import './App.css';
import Cases from './pages/Cases';
import CaseDetails from './pages/CaseDetails';

const { Content } = Layout;

function App() {

  useEffect(() => {
    initGA();
  }, []);

  const isOnline = useNetworkStatus();
  useRetryUpload(isOnline, 20000);

  return (
    <ErrorBoundary>
      <div className='App' data-testid='app'>
        <ConfigProvider theme={{
          token: {
            colorPrimary: 'var(--main-color)',
            colorPrimaryActive: 'var(--main-color)',
            colorPrimaryHover: 'var(--hover-color)',

          },
          components: {
            Tabs: {
              itemSelectedColor: '#333333',
              itemHoverColor: 'var(--main-color)',
            },
            Button: {
              colorPrimary: 'var(--main-color)',
            }
          }
        }}>
          <BrowserRouter>
            <PageViewHandler/>
            <Layout>
              <Layout>
                <Content>
                  <Routes>
                    {/* Protected routes */}
                    <Route path="/" element={<ProtectedLayout><Home /></ProtectedLayout>}/>
                    <Route path="/settings" element={<ProtectedLayout><Settings/></ProtectedLayout>}/>
                    <Route path="/sessions" element={<ProtectedLayout><Sessions/></ProtectedLayout>}/>
                    <Route path="/sessions/:uuid" element={<ProtectedLayout hideSidenav={true}><SessionDetails/></ProtectedLayout>}/>
                    <Route path="/queries/new" element={<ProtectedLayout><QueryNew/></ProtectedLayout>}/>
                    <Route path="/queries/:uuid" element={<ProtectedLayout><QueryDetails/></ProtectedLayout>}/>
                    <Route path="/queries" element={<ProtectedLayout><QueryFromUrl/></ProtectedLayout>}/>
                    <Route path="/cases" element={<ProtectedLayout><Cases/></ProtectedLayout>}/>
                    <Route path="/cases/:uuid" element={<ProtectedLayout hideSidenav={true}><CaseDetails/></ProtectedLayout>}/>

                    {/* Public routes */}
                    <Route path="/sign_in" element={<PublicLayout><SignIn/></PublicLayout>}/>
                    <Route path="/sign_up" element={<PublicLayout><SignUp/></PublicLayout>}/>
                    <Route path="/confirmation" element={<PublicLayout><EmailConfirmation/></PublicLayout>}/>
                    <Route path="/reset_password" element={<PublicLayout><ResetPasswordRequest/></PublicLayout>}/>
                    <Route path="/password/edit" element={<PublicLayout><ResetPasswordForm/></PublicLayout>}/>
                    <Route path="/auth/callback" element={<BlankLayout><GoogleAuthCallback/></BlankLayout>}/>
                    
                    {/* Public query routes */}
                    <Route path="/q/share/:share_token" element={<PublicLayout><SharedQuery/></PublicLayout>}/>
                    <Route path="/q/:slug/:uuid" element={<PublicLayout><PublicQueryDetails/></PublicLayout>}/>
                    <Route path="/q/queries" element={<PublicLayout><PublicQueries/></PublicLayout>}/>

                    {/* Legal documents */}
                    <Route path="/terms_en" element={<PublicLayout><LegalDocument fileName="terms_en.md"/></PublicLayout>}/>
                    <Route path="/terms_es" element={<PublicLayout><LegalDocument fileName="terms_es.md"/></PublicLayout>}/>
                    <Route path="/privacy_en" element={<PublicLayout><LegalDocument fileName="privacy_en.md"/></PublicLayout>}/>
                    <Route path="/privacy_es" element={<PublicLayout><LegalDocument fileName="privacy_es.md"/></PublicLayout>}/>
                    <Route path="/usage_en" element={<PublicLayout><LegalDocument fileName="usage_en.md"/></PublicLayout>}/>
                    <Route path="/usage_es" element={<PublicLayout><LegalDocument fileName="usage_es.md"/></PublicLayout>}/>

                    {/* 404 Not Found Page */}
                    <Route path="*" element={<NotFoundPage/>}/>
                  </Routes>
                </Content>
              </Layout>
            </Layout>
            <Toaster
              position="top-center"
              containerStyle={{
                marginTop: '55px',
              }}
            />
          </BrowserRouter>
        </ConfigProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;
