import authRequest from '../authRequest';
import CaseTextEntryPresenter from '../../presenters/CaseTextEntryPresenter';

const fetchCaseTextEntry = async (uuid) => {
  try {
    const response = await authRequest(`/case_text_entries/${uuid}`, { method: 'GET' });
    if (response.status !== 200) {
      throw new Error('Fetching case text entry failed');
    }
    return new CaseTextEntryPresenter(response.data);
  } catch (error) {
    console.error('Fetching case text entry failed:', error);
    throw error;
  }
};

export default fetchCaseTextEntry;
