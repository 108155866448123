import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { MessageOutlined } from '@ant-design/icons';
import QueryForm from '../query/QueryForm';
import ExampleQuestionButton from '../query/ExampleQuestionButton';

const { Title } = Typography;

const QueryFormCard = ({ initialQuestion, onSubmit, handleExampleQuery }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Title level={4} style={{ marginTop: '0', marginBottom: '15px' }}>
        <MessageOutlined style={{ marginRight: '8px' }} />
        {t('query_new.title')}
      </Title>
      <QueryForm initialQuestion={initialQuestion} onSubmit={onSubmit} />

      <Title level={5}>{t('query_new.fields.examples_title')}</Title>
      <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <ExampleQuestionButton
            text={t('query_new.fields.example_q1')}
            onClick={() => handleExampleQuery(t('query_new.fields.example_q1'))}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <ExampleQuestionButton
            text={t('query_new.fields.example_q2')}
            onClick={() => handleExampleQuery(t('query_new.fields.example_q2'))}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <ExampleQuestionButton
            text={t('query_new.fields.example_q3')}
            onClick={() => handleExampleQuery(t('query_new.fields.example_q3'))}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <ExampleQuestionButton
            text={t('query_new.fields.example_q4')}
            onClick={() => handleExampleQuery(t('query_new.fields.example_q4'))}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default QueryFormCard;
