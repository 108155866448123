import React, { useState } from 'react';
import {Row, Col, Tabs, Grid} from 'antd';
import NoteCard from './NoteCard';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';
import SessionInsights from './SessionInsights';
import SessionDetailsNotepad from "./SessionDetailsNotepad";

const { useBreakpoint } = Grid;

const SessionDetailsTabs = ({ session }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [activeTab, setActiveTab] = useState('soapNote');
  const activeDoctorNote = session.getLastDoctorNote();
  const activePatientInstructionsNote = session.getLastPatientInstructionsNote();

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'patientInstructions') {
      logEvent('PatientInstructionsTab', 'Open');
    }
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
      <Col xs={24} sm={24} md={ session.displayWidgetsColumn() ? 16 : 24}>
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          type="card"
          animated={true}
          className="session-details--tabs"
        >
          <Tabs.TabPane tab={t(`session_details.note_types.${session.getLastDoctorNoteType()}`)} key="soapNote">
            <div className='session-details--tab-content' data-testid='soap-tab'>
              <NoteCard
                note={activeDoctorNote}
                session={session}
              />
            </div>
          </Tabs.TabPane>
          {activePatientInstructionsNote && <Tabs.TabPane tab={t('session_details.fields.tab_patient_instructions_note')} key='patientInstructions'>
            <div className='session-details--tab-content' data-testid='patient-instructions-tab'>
              <NoteCard
                note={activePatientInstructionsNote}
                session={session}
              />
            </div>
          </Tabs.TabPane>}
        </Tabs>
      </Col>

      { session.displayWidgetsColumn() &&
        <Col xs={24} sm={24} md={8}>
          <>
            {activeDoctorNote.hasInsights() &&
              <div style={{marginTop: screens.md ? '40px' : '10px'}}>
                <SessionInsights note={activeDoctorNote}/>
              </div>
            }
            {/*TODO: introduce back once ready*/}
            {/*<div style={{marginTop: screens.md ? '40px' : '10px'}}>*/}
            {/*  <SessionDetailsNotepad session={session}/>*/}
            {/*</div>*/}
          </>
        </Col>
      }
    </Row>
  );
};

export default SessionDetailsTabs;
