import React, { useState, useRef, useEffect } from 'react';
import {Card, Button, Grid} from 'antd';
import { useTranslation } from 'react-i18next';
import { ExpandOutlined } from '@ant-design/icons';
import CaseQueryFeed from './CaseQueryFeed';
import CaseQueryInputArea from './CaseQueryInputArea';
import CaseQuerySuggestedQuestions from './CaseQuerySuggestedQuestions';
import useCaseQuerySubmission from '../../hooks/useCaseQuerySubmission';
import ExpandableContainer from '../ExpandableContainer';

const titleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const { useBreakpoint } = Grid;


const CaseQueries = React.memo(({ caseUuid, caseQueries }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const { queryFeed, isSubmitting, submitQuery } = useCaseQuerySubmission(caseUuid, caseQueries);
  const [isExpanded, setIsExpanded] = useState(false);
  const screens = useBreakpoint();


  const feedRef = useRef(null);

  useEffect(() => {
    if (feedRef.current) {
      feedRef.current.scrollTop = feedRef.current.scrollHeight;
    }
  }, [queryFeed]);

  const handleSubmit = () => {
    if (query.trim()) {
      submitQuery(query);
      setQuery('');
    }
  };

  const handleQuestionClick = (question) => {
    submitQuery(question);
  };

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const handleCollapse = () => {
    setIsExpanded(false);
  };

  return (
    <>
      <Card
        title={(
          <div style={titleStyle}>
            {t('case_queries.title')}
            <Button
              icon={<ExpandOutlined />}
              onClick={handleExpand}
              type="text"
            >
              {screens.sm && t('case_queries.expand_label')}
            </Button>
          </div>
        )}
      >
        <CaseQueryFeed queryFeed={queryFeed} />
        <CaseQueryInputArea
          query={query}
          setQuery={setQuery}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
        <CaseQuerySuggestedQuestions onQuestionClick={handleQuestionClick} />
      </Card>

      <ExpandableContainer isExpanded={isExpanded} onClose={handleCollapse}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div ref={feedRef} style={{ flex: 1, overflowY: 'auto' }}>
            <CaseQueryFeed queryFeed={queryFeed} isExpanded={isExpanded} />
          </div>
          <div>
            <CaseQueryInputArea
              query={query}
              setQuery={setQuery}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>
      </ExpandableContainer>
    </>
  );
});

export default CaseQueries;
