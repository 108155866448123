import authRequest from '../authRequest';
import CasePresenter from '../../presenters/CasePresenter';

const fetchCasesList = async (currentPage) => {
  try {
    const response = await authRequest(`/cases/?page=${currentPage}`, { method: 'GET' });
    if (response.status === 200) {
      const cases = response.data.cases.map(caseData => new CasePresenter(caseData));
      const pagination = {
        total_entries: response.data.meta.total_entries,
        total_pages: response.data.meta.total_pages,
        current_page: response.data.meta.current_page
      };
      return { cases, pagination };
    } else {
      throw new Error('Fetching cases failed');
    }
  } catch (error) {
    console.error('Fetching cases failed:', error);
    throw error;
  }
};

export default fetchCasesList;
