import React, { useState } from 'react';
import { Card, Input, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { handleError } from "../../utils/errorHandling";
import { toast } from 'react-hot-toast';
import updateNote from '../../utils/session/updateNote';

const { TextArea } = Input;

const NoteCardEdit = ({ currentNote, handleSaveButtonClick, handleCancelButtonClick }) => {
  const { t } = useTranslation();
  const [noteBody, setNoteBody] = useState(currentNote.getBody());
  const [saving, setSaving] = useState(false);

  const onNoteBodyChange = (e) => {
    setNoteBody(e.target.value);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await updateNote(currentNote.getUUID(), { user_body: noteBody });
      currentNote.setUserBody(noteBody);
      handleSaveButtonClick();
      toast.success(t('session_notes.update.success'));
    } catch (error) {
      handleError(error);
      toast.error(t('common.errors.general'));
    } finally {
      setSaving(false);
    }
  };

  const headerContent = (
    <div className='session-details--buttons-container'>
      <Button
        onClick={handleSave}
        type="primary"
        data-testid="save-button"
        disabled={saving}
        loading={saving}
      >
        {!saving && <SaveOutlined />} {t('session_details.save')}
      </Button>
      <Button
        onClick={handleCancelButtonClick}
        type="default"
        data-testid="cancel-button"
        disabled={saving}
      >
        {t('session_details.cancel')}
      </Button>
    </div>
  );

  return (
    <div>
      <Card
        style={{ borderRadius: '0 10px 10px 10px', borderColor: '#E0E0E0', borderTop: 'none' }}
        bordered={true}
        extra={headerContent}
      >
        <TextArea
          value={noteBody}
          onChange={onNoteBodyChange}
          autoSize={{ minRows: 20, maxRows: 50 }}
          style={{ width: '100%', height: '100%' }}
        />
      </Card>
    </div>
  );
};

export default NoteCardEdit;
