import React from 'react';
import { Card, Typography, Grid } from 'antd';
import QueryForm from './QueryForm';
import { useTranslation } from 'react-i18next';

const { Paragraph, Text, Title } = Typography;
const { useBreakpoint } = Grid;

const QueryFollowup = ({ queryUuid, onReloadRecentQueries }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  return (
    <Card
      style={{ marginTop: '20px' }}
      title={
        <>
          <Title level={ screens.md ? 4 : 5 }>
            {t('query_followup.title')}
          </Title>
          <Paragraph style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            <Text type="secondary">
              {t('query_followup.subtitle')}
            </Text>
          </Paragraph>
        </>
      }
    >
      <QueryForm onSubmit={onReloadRecentQueries} previousQueryUuid={queryUuid} placeholder={ t('query_followup.fields.placeholder')} shouldSubmit={false}/>
    </Card>
  );
};

export default QueryFollowup;
