import React from 'react';
import {Card, Skeleton, Button, Typography} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import AnswerMarkdown from './AnswerMarkdown';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { logEvent } from '../../analytics';
import QueryFollowup from './QueryFollowup';
import GetStartedButton from '../buttons/GetStartedButton';
import QueryCitations from './QueryCitations';
import QueryDetailsButtons from './QueryDetailsButtons';

const { Title } = Typography;

const QueryDetailsCard = ({ query, onReloadRecentQueries = null, protectedPage = true }) => {
  const { t } = useTranslation();

  // Scroll to the citation on click
  const handleCitationClick = (citationNumber) => {
    const citationElement = document.getElementById(`citation-${citationNumber}`);
    if (citationElement) {
      citationElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleQuestionCopy = () => {
    navigator.clipboard.writeText(query.getQuestion() || '');
    toast.success(t('query_details.fields.question_copied'));
    logEvent('QueryDetails', 'CopyQuestion');
  };

  const handleAnswerCopy = () => {
    navigator.clipboard.writeText(query.getAnswer() || '');
    toast.success(t('query_details.fields.answer_copied'));
    logEvent('QueryDetails', 'CopyAnswer');
  };

  const handleShare = () => {
    navigator.clipboard.writeText(query.getShareUrl());
    toast.success(t('query_details.fields.link_copied'));
    logEvent('QueryDetails', 'ShareLink');
  };

  if (!query) return null;

  return (
    <>
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
            <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', margin: '10px 0', fontSize: 'large' }}>
              {query?.getQuestion()}
            </div>
            {protectedPage && (
              <div>
                <Button icon={<CopyOutlined />} onClick={handleQuestionCopy} style={{ marginLeft: '10px', padding: '10px' }} />
              </div>
            )}
          </div>
        }
      >
        <div className="query-card-content" style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
          {query?.getAnswer() ? (
            <>
              <AnswerMarkdown onCitationClick={handleCitationClick}>{query.getAnswer()}</AnswerMarkdown>
              {query.isCompleted() && <QueryCitations citations={query.getCitations()} />}
              {query.isCompleted() && protectedPage && (
                <QueryDetailsButtons
                  query={query}
                  onAnswerCopy={handleAnswerCopy}
                  onShare={handleShare}
                />
              )}
            </>
          ) : (
            <Skeleton active />
          )}
        </div>
      </Card>

      {/* Conditionally render QueryFollowup based on protectedPage flag */}
      {query.isCompleted() && protectedPage && (
        <QueryFollowup
          queryUuid={query.getUUID()}
          onReloadRecentQueries={onReloadRecentQueries}
        />
      )}

      {/* Get Started with Itaca button */}
      {!protectedPage && (
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
          <Title level={4}>{t('marketing_info.fields.title')}</Title>
          <GetStartedButton />
        </div>
      )}
    </>
  );
};

export default QueryDetailsCard;
