import authRequest from '../authRequest';
import PublicQueryPresenter from '../../presenters/PublicQueryPresenter';

const fetchPublicQuery = async (uuid) => {
  try {
    const response = await authRequest(`/q/public/${uuid}`, { method: 'GET' });
    return new PublicQueryPresenter(response.data);
  } catch (error) {
    console.error('Fetching public query failed:', error);
    throw error;
  }
};

export default fetchPublicQuery;
