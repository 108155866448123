class CaseQueryPresenter {
  constructor(data) {
    this.uuid = data.uuid;
    this.question = data.question;
    this.genAnswer = data.gen_answer;
    this.completed = data.completed;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
  }

  checkValue(value) {
    return value && value !== "0" ? value : 'N/A';
  }

  getUUID() {
    return this.checkValue(this.uuid);
  }

  getQuestion() {
    return this.checkValue(this.question);
  }

  getAnswer() {
    return this.genAnswer;
  }

  isCompleted() {
    return Boolean(this.completed);
  }

  getCreatedAt(format = 'MMMM D, YYYY') {
    return this.createdAt ? moment(this.createdAt).format(format) : 'N/A';
  }

  getUpdatedAt(format = 'MMMM D, YYYY') {
    return this.updatedAt ? moment(this.updatedAt).format(format) : 'N/A';
  }

  hasAnswer() {
    return !!this.genAnswer;
  }
}

export default CaseQueryPresenter;
