import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment-timezone';
import 'moment/locale/es';
import translationEN from "../src/translationEN.json";
import translationES from "../src/translationES.json";

// Initialize moment with the detected or fallback language
function configMoment(language) {
  // Set moment locale to the detected or fallback language
  moment.locale(language);
}

i18n
  // Use LanguageDetector to detect the user's language
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Initialize i18next
  .init({
    debug: true,
    fallbackLng: 'en', // Fallback language if detection fails
    detection: {
      // Prioritize the following detection mechanisms
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'querystring', 'path', 'subdomain'],
      // Cache the language in localStorage and cookie
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false, // Not needed for React
    },
    // Define available languages and their translations
    resources: {
      en: {
        translation: translationEN
      },
      es: {
        translation: translationES
      }
    }
  })
  .then(() => {
    // After initialization, set the moment locale based on the detected language
    const language = i18n.language || window.navigator.language;
    configMoment(language);
  });

export default i18n;
