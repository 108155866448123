import React from 'react';
import { Typography, List, Avatar, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

const MarketingInfo = () => {
    const { Title} = Typography;
    const { t } = useTranslation();

    return (
        <div className='marketing-info'>
            <div className='marketing-info--header'>
                <Title level={4}>{t('marketing_info.fields.title')}</Title>
            </div>
            <ConfigProvider theme={{
                token: {
                    colorTextDescription: 'var(--text-color)',
                },
            }}>
                <List>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src='https://img.icons8.com/plasticine/100/storytelling.png' />}
                            title={t('marketing_info.fields.feature1_title')}
                            description={t('marketing_info.fields.feature1_body')}
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src='https://img.icons8.com/plasticine/100/document.png' />}
                            title={t('marketing_info.fields.feature2_title')}
                            description={t('marketing_info.fields.feature2_body')}
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src='https://img.icons8.com/plasticine/100/cloud-folder.png' />}
                            title={t('marketing_info.fields.feature3_title')}
                            description={t('marketing_info.fields.feature3_body')}
                        />
                    </List.Item>
                </List>
            </ConfigProvider>
        </div>
    );
};

export default MarketingInfo;
