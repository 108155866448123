import React, { useRef, useEffect, useState } from 'react';
import { List, Button } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CaseQueryBubble from './CaseQueryBubble';

const CaseQueryFeed = React.memo(({ queryFeed, isExpanded }) => {
  const { t } = useTranslation();
  const queryContainerRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false); // State to toggle button visibility

  const containerStyle = {
    maxHeight: isExpanded ? 'none' : '500px', // Adjust maxHeight based on expanded state
    overflowY: 'auto',
    padding: '12px',
    position: 'relative',
  };

  const listItemStyle = {
    display: 'flex',
    padding: '10px 0',
    border: 'none',
  };

  const questionStyle = {
    justifyContent: 'flex-end',
  };

  const answerStyle = {
    justifyContent: 'flex-start',
  };

  const emptyTextStyle = {
    color: '#666',
  };

  useEffect(() => {
    if (queryContainerRef.current) {
      queryContainerRef.current.scrollTop = queryContainerRef.current.scrollHeight;
    }
  }, [queryFeed]); // Scroll to bottom whenever the queryFeed updates

  useEffect(() => {
    const handleScroll = () => {
      if (queryContainerRef.current) {
        // Show the button only if not at the top of the scrollable container
        setShowScrollButton(queryContainerRef.current.scrollTop > 0);
      }
    };

    const containerRef = queryContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener('scroll', handleScroll);
    }

    // Cleanup listener on unmount
    return () => {
      if (containerRef) {
        containerRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, []); // Run this only once after component mounts

  const scrollToTop = () => {
    if (queryContainerRef.current) {
      queryContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div
      ref={queryContainerRef}
      style={containerStyle}
    >
      {queryFeed.length > 0 && showScrollButton && ( // Show button only if there are items and the user isn't at the top
        <div style={{ position: 'sticky', top: '-12px', right: 0, padding: '0px', marginTop: '0px', zIndex: 1, textAlign: 'center' }}>
          <Button
            type="default"
            shape="round"
            icon={<UpOutlined />}
            size="middle"
            onClick={scrollToTop}
          >
            {t('case_queries.scroll_up')}
          </Button>
        </div>
      )}
      <List
        dataSource={queryFeed}
        renderItem={(item) => (
          <>
            <List.Item style={{ ...listItemStyle, ...questionStyle }}>
              <CaseQueryBubble caseQueryUuid={item.uuid} isQuestion content={item.question} completed />
            </List.Item>
            <List.Item style={{ ...listItemStyle, ...answerStyle }}>
              <CaseQueryBubble caseQueryUuid={item.uuid} content={item.answer} completed={item.completed} />
            </List.Item>
          </>
        )}
        locale={{ emptyText: <span style={emptyTextStyle}>{t('case_queries.ask_any_question')}</span> }}
      />
    </div>
  );
});

export default CaseQueryFeed;
