import React from 'react';
import { Button } from 'antd';
import { CopyOutlined, ShareAltOutlined } from '@ant-design/icons';
import ThumbsRating from '../ThumbsRating';
import { useTranslation } from 'react-i18next';

const QueryDetailsButtons = ({ query, onAnswerCopy, onShare }) => {
  const { t } = useTranslation();

  if (!query) return null;

  return (
    <div>
      {/* Thumbs Rating */}
      <div style={{ display: 'flex', justifyContent: 'left', marginTop: '30px' }}>
        <ThumbsRating
          rateableUuid={query.getUUID()}
          rateableType="Query"
          hint={t('query_details.fields.rating_hint')}
        />
      </div>

      {/* Copy and Share Buttons */}
      <div style={{ justifyContent: 'right', marginTop: '30px' }}>
        <Button icon={<CopyOutlined />} onClick={onAnswerCopy}>
          {t('query_details.fields.copy')}
        </Button>
        {query.getShareToken() && (
          <Button icon={<ShareAltOutlined />} onClick={onShare} style={{ marginLeft: '10px' }}>
            {t('query_details.fields.share')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default QueryDetailsButtons;