import React from 'react';
import { Typography, Button } from 'antd';
import { LoadingOutlined, CopyOutlined } from '@ant-design/icons';
import { toast } from "react-hot-toast";
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';
import ThumbsRating from "../ThumbsRating";

const { Text } = Typography;

const styles = {
  bubble: {
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '90%',
  },
  question: {
    backgroundColor: '#e6f7ff',
    alignSelf: 'flex-end',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  },
  answer: {
    backgroundColor: '#fff',
    alignSelf: 'flex-start',
  },
  copyButton: {
    marginTop: '0px',
    marginRight: '20px'
  },
};

const CaseQueryBubble = ({ caseQueryUuid, isQuestion, content, completed }) => {
  const { t } = useTranslation();
  const bubbleStyle = {
    ...styles.bubble,
    ...(isQuestion ? styles.question : styles.answer),
  };

  const handleCopy = () => {
    logEvent('CaseQueryAnswer', 'Copy');
    navigator.clipboard.writeText(content).then(() => {
      toast.success(t('case_query_bubble.copied'));
    });
  };

  return (
    <div style={bubbleStyle}>
      <Text strong style={{ display: 'block', marginBottom: '2px' }}>
        {isQuestion ? t('case_query_bubble.me') : t('case_query_bubble.assistant')}
      </Text>
      {isQuestion ? (
        <Text>{content}</Text>
      ) : (
        <>
          <ReactMarkdown components={{
            p: ({ node, ...props }) => <p style={{ marginTop: 0 }} {...props} />
          }}>{content}</ReactMarkdown>
          {completed &&
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Button
                type="default"
                icon={<CopyOutlined/>}
                onClick={handleCopy}
                style={styles.copyButton}
                data-testid="copy-answer-button"
              >
                {t('case_query_bubble.copy')}
              </Button>
              <ThumbsRating
                rateableUuid={caseQueryUuid}
                rateableType="CaseQuery"
              />
            </div>
          }
        </>
      )}
      {!completed && <LoadingOutlined style={{ marginLeft: '10px' }} />}
    </div>
  );
};

export default CaseQueryBubble;
