import authRequest from '../authRequest';
import QueryPresenter from "../../presenters/QueryPresenter";

const fetchSharedQuery = async (share_token) => {
  try {
    const response = await authRequest(`/q/share/${share_token}`, { method: 'GET' });
    if (response.status === 200) {
      return new QueryPresenter(response.data);
    } else {
      throw new Error('Failed to create query');
    }
  } catch (error) {
    console.error('Fetching shared query failed:', error);
    throw error;
  }
};

export default fetchSharedQuery;
